
import { switch_Service_Type_Id } from "utils/data/switch" ;
import axios from "utils/axios" ;
import { Toast } from 'templates/note/Toast' ;
import { Shop_Status } from "utils/Interface_Type";


type Type = "left" | "right" ;


type Arrow = {
    direction : Type ;
    data      : any ;
}


const status_Right = {
   "到店等候中" : "到店美容中" ,
   "到店美容中" : "洗完等候中" ,
   "洗完等候中" : "已回家( 房 )"
} ;


const status_Left = {
   "已回家( 房 )" : "洗完等候中" ,
   "洗完等候中"   : "到店美容中" ,
   "到店美容中"   :  "到店等候中" 
} ;




// # 箭頭：調整到店狀態
const Shop_Status_Arrow = ( { direction , data } : Arrow ) => {


   // 到店狀態
   const shop_Status = data[ "shop_status" ]  ;

   if( !shop_Status ) return <> 未取得到店狀態 </> ;


   
   // 取得 _ "服務( 基礎、洗澡、美容 ) 相對應服務資料表 id"、"服務 Url ( /basics 、/bathes、/beauties )"
   const { service_Id , service_Url } = switch_Service_Type_Id( data ) ; 



   // 點選 _ 修改狀態
   const click_Status = ( type : Type ) => {

      if( !shop_Status || !service_Url || !service_Id ) return alert( "未取得修改資料" ) ;


      const _shopStatus = type === "right" ? 
                                    status_Right[ shop_Status as keyof typeof status_Right ] : 
                                    status_Left[ shop_Status  as keyof typeof status_Left ] ;


      if( !_shopStatus ) return alert( "未取得到店狀態" ) ;                              

      axios.put( `${ service_Url }/${ service_Id }` , { shop_status : _shopStatus as Shop_Status } ).then( res => {

         Toast( "到店狀態，修改成功" ) ;

      }).catch( err => {

         Toast( "到店狀態，修改失敗" ) ;

      }) ;
   
   } ;


  // ------------------


  // 向左調整
  if( direction === "left" && shop_Status !== "到店等候中" ) 
        return <i onClick   = { () => click_Status( "left" ) } 
                  className = "fas fa-angle-left f_16 pointer absolute" style = { { left:"10px" , top : "3px" } }  /> 


  // 向右調整
  if( direction === "right" && shop_Status !== "已回家( 房 )" )               
        return <i onClick   = { () => click_Status( "right" ) } 
                  className = "fas fa-angle-right f_16 pointer absolute"  style = {{ right : "0px" , top : "3px" }} /> 

  return <></>                   

} ;

export default Shop_Status_Arrow   