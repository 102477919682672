
import { QueryClient } from 'react-query' ;
import { Toast } from 'templates/note/Toast' ;


// 查詢錯誤處理
function queryErrorHandler(error: unknown): void {

  // error is type unknown because in js, anything can be an error (e.g. throw(5))
  const title = error instanceof Error ? error.message : 'error connecting to server';

  // toasts
  Toast( title ) ;

}


export function generateQueryClient() : QueryClient {

  return new QueryClient({

                defaultOptions : {

                                    queries : {

                                      //  onError              : queryErrorHandler ,

                                      //  staleTime            : 600000 , // 10 minutes
                                      //  cacheTime            : 900000 , // default cacheTime is 5 minutes; doesn't make sense for staleTime to exceed cacheTime
                                     
                                      //  refetchOnMount       : false ,
                                      //  refetchOnWindowFocus : false ,
                                      //  refetchOnReconnect   : false

                                    } ,

                                    mutations : {

                                     //   onError              : queryErrorHandler

                                    }

                                }

         } ) ;

}

export const queryClient = generateQueryClient();