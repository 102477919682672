
/*
*
*  實體化 axios 模組 --> 作為使用 axios 時，重複的配置  
*
*/

import _axios from 'axios' ;



const axios = baseURL => {

    const instance = _axios.create({
        
        // baseURL : baseURL || 'http://localhost/Laravel_Projects/gogopark/public/index.php/api' ,    // 本機開發
         
        // baseURL : baseURL || 'http://localhost:7777/Laravel_Projects/gogopark/public/index.php/api' ,  // 本機開發 ( for Mac )

        // baseURL : baseURL || 'http://demo04.cchouse.com.tw/backend_new/public/index.php/api' ,      // Demo4 測試空間 ( backend 資料夾 )
        
        // baseURL : baseURL || 'http://demo05.cchouse.com.tw/backend/public/index.php/api' ,          // Demo5 測試空間 ( backend 資料夾 )
  
        // baseURL : baseURL || 'http://erp2021.gogopark.com.tw/backend/public/index.php/api' ,        // ERP2021       ( backend 資料夾 )        
                           
        // baseURL : baseURL || 'https://pet.cchouse.com.tw/backend/public/index.php/api' ,            // pet_cchouse_2023 ( backend 資料夾 )        
        
        // baseURL : baseURL || 'https://pet24801.cchouse.com.tw/backend/public/index.php/api' ,       // pet24801_cchouse 五股店 ( backend 資料夾 )        
      
        baseURL : baseURL || 'https://pettest01.cchouse.com.tw/backend/public/index.php/api' ,            // pettest01
	  
        timeout : 100000  // ( 原先為 1000 ms --> 設長點，避免資料過多情況下，來不及取得，即拋出 timeout 錯誤 : Error: timeout of 1000ms exceeded )

    }) ;
    

    return instance ;

} ;


// 不同狀況回傳 :
export { axios } ;        // 有參數 --> 使用
export default axios() ;  // 無參數 --> 預設直接