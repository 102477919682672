

/* @ 快取資料  */
interface ICache {

 



}

const initState = {

   

} ;


const reducer_Cache = ( state : ICache = initState , action : any ) => {


    switch( action.type ){


        default : return state ;

    }



} ;

export default reducer_Cache ;
