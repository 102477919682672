
import { useMutation , useQueryClient } from "react-query" ;
import { create_Lodge } from "utils/api/api_Lodge" ;
import { useHistory } from "react-router-dom" ;
import { Toast } from 'templates/note/Toast' ;
import { useDispatch } from "react-redux" ;
import { set_Side_Panel } from "store/actions/action_Global_Layout" ;


// @ 新增 _ 住宿單
export const useCreate_Lodge = ( ) => { 


    const dispatch    = useDispatch() ;
    const history     = useHistory() ;

    const queryClient = useQueryClient() ;

    const { mutate } = useMutation( 
                                    ( obj : any ) => create_Lodge( obj ) ,
                                    { 
                                        onSuccess : () => {

                                                             // 刪除快取
                                                             queryClient.invalidateQueries() ; 

                                                             // 新增成功
                                                             Toast( "已新增 : 住宿單" ) ;
                                                            
                                                             // 關掉右側面板
                                                             dispatch( set_Side_Panel( false , null , {} ) ) ;

                                                             // 前往相對應頁面
                                                             history.push( "/wrongpath" ) ;  // 錯誤路徑
                                                             history.push( "/lodge" ) ;  // 正確路徑

                                                          }
                                    }
                                  ) ;

    return mutate ;


}