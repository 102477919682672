


// 搜尋備註
export const Filter_Note = () => <span className = "m_Left_20"> 
                                    <i className = "fas fa-info-circle pointer m_Right_5"></i> 
                                    搜尋範圍 : 最近 300 筆方案紀錄
                                 </span>

   
// 沒有篩選資料
export const No_Filter_Data = () => <div className = "tag is-large is-white w-full m_Top_50 fDred" > 
                                       <i className = "fas fa-info" ></i> &nbsp; 未取得篩選資料 
                                    </div>