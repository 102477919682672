

// @ 操作日誌列表
const Log_List = () => {


    return  <div className="columns is-multiline is-mobile">

               <div className="column is-12-desktop">
 
                  <b className="tag is-medium hover"> 新增 </b>

               </div>

            </div>
                
} ;

export default Log_List
       